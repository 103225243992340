<template>
	<div class="main-containter" v-title data-title="三特科技-首页">
		<FHeader></FHeader>
		<FBanner></FBanner>
		<FTitle></FTitle>
		<article>
			<div class="about">
				<FContentTitle :titleName="titleName1"></FContentTitle>
				<FHomeAbout></FHomeAbout>
			</div>
			<div class="business">
				<FContentTitle :titleName="titleName2"></FContentTitle>
				<FHomeBusiness :data="softwareList"></FHomeBusiness>
			</div>
			<div class="scenic">
				<FContentTitle :titleName="titleName3"></FContentTitle>
				<FHomeScenic :data="scenicList"></FHomeScenic>
			</div>
			<div class="news">
				<FContentTitle :titleName="titleName4"></FContentTitle>
				<FHomeNews :data="newsList"></FHomeNews>
			</div>
		</article>
		<Footer></Footer>
	</div>
</template>

<script>
	import FHeader from "../../components/f/FHeader.vue"
	import FBanner from "../../components/f/FBanner.vue"
	import FTitle from "../../components/f/FTitle.vue"
	import FContentTitle from "../../components/f/FContentTitle.vue"
	import FHomeAbout from "../../components/f/FHomeAbout.vue"
	import FHomeBusiness from "../../components/f/FHomeBusiness.vue"
	import FHomeScenic from "../../components/f/FHomeScenic.vue"
	import FHomeNews from "../../components/f/FHomeNews.vue"
	import Footer from "../../components/f/Footer.vue"

	import {queryScenicList,querySoftwareList,queryArticleList} from "@/api/front";
	export default {
		name: "Index",
		components: {
			FHeader,
			FBanner,
			FTitle,
			FContentTitle,
			FHomeAbout,
			FHomeBusiness,
			FHomeScenic,
			FHomeNews,
			Footer
		},
		data() {
			return {
				titleName1: '关于我们',
				titleName2: '核心业务',
				titleName3: '业务景区',
				titleName4: '新闻聚焦',
				softwareList: [],
				scenicList:[],
				newsList:[],
			}
		},
		mounted() {
			this.scrollcolor()
			this.queryScenic(5)
			this.querySoftware(6)
			this.queryArticle(11)	
		},
		methods: {
			scrollcolor() {
				this.$nextTick(() => {
					window.onscroll = function() {
						let header = document.getElementById('header')
						let item = header.getElementsByClassName('item')
						if (window.pageYOffset>0) {
							// navbox.style.backgroundColor="#FFFFFF"
						} else {
							// navbox.style.backgroundColor="#FFFFFF"
						}
					}
				})
			},
			querySoftware(id){
				let param = {
					title: '',
					channelId: id,
					page: 1,
					pageSize: 3,
				}
				querySoftwareList(param).then(data => {
					this.softwareList=data.data.list
					// console.log("软件",this.softwareList)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			queryScenic(id){
				let param = {
					title: '',
					channelId: id,
					page: 1,
					pageSize: 3,
				}
				queryScenicList(param).then(data => {
					this.scenicList=data.data.list
					// console.log("景区",this.scenicList)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			queryArticle(id){
				let param = {
					title: '',
					channelId: id,
					page: 1,
					pageSize: 4,
				}
				queryArticleList(param).then(data => {
					this.newsList=data.data.list
					// console.log("新闻",this.newsList)
				}).catch(error => {
					this.$message.error(error)
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.main-containter {
		min-width: 1000px;
		.news{
			background-image: url(../../assets/fImage/homeNewsBg.jpg);
			background-size: cover;
		}
	}
</style>
