<template>
	<div class="scenicContent">
		<div class="item" v-for="(item,index) in scenicList" :key="index" @click="goScenicDetail(item)">
			<div class="image">
				<img :src="item.image">
			</div>
			<div class="text">
				<span>{{item.title}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		data() {
			return {
				scenicList: [],
			}
		},
		watch: {
			data: {
				immediate: true, // 这句重要
				handler(val) {
					// console.log("栏目id", val)
					this.scenicList = val
				}
			}
		},
		methods:{
			goScenicDetail(scenicItem){
				let routerUrl=this.$router.resolve({
					path: "/scenic_detail/",
					query: {
						channelId:1,
						id:scenicItem.id,
					},
				})
				window.open(routerUrl.href, '_blank');
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.scenicContent {
		padding: 30px 15% 80px 15%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
		.item {
			width: 380px;
			height: 300px;
			border: 1px solid #BFBFBF;
			cursor: pointer;
			background-color: #797979;
			img {
				width: 380px;
				height: 245px;
			}

			.text {
				span {
					display: inline-block;
					height: 50px;
					width: 380px;
					text-align: center;
					font-size: 22px;
					font-family: Adobe Heiti Std R;
					color: #FFFFFF;
					line-height: 50px;
				}
			}
		}
		.item:hover{
			background-color: #1A86FF;
		}
	}
</style>
