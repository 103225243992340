<template>
	<div id="title">
		<div class="tip">
			<span>——为旅游行业提供高品质的智慧运营服务</span>
		</div>
		<div class="name">
			<span>武汉三特索道科技发展有限公司</span>
		</div>
		<div class="slogan">
			<span>全面提升行业索道智慧运营管理水平，探索面向
			索道全产业链的、基于工业互联网平台的全方位的服务，
			为索道的开发建设及运营管理提供高质量、系统化的解决
			方案，为旅游行业提供高品质的智慧运营服务</span>
		</div>
		<div class="button" @click="goAbout()">查看详情</div>
	</div>
</template>

<script>
	export default{
		methods:{
			goAbout(){
				this.$router.push({
					path: "/channel_detail/"+'4',
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	#title{
		position: absolute;
		top: 0;
		z-index:5;
		width: 750px;
		height: 300px;
		color: #FFFFFF;
		margin: 250px 30% 100px 30%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		.tip{
			width: 355px;
			height: 20px;
			font-size: 18px;
			font-family: Adobe Heiti Std R;
			line-height: 32px;
			margin-bottom: 30px;
		}
		.name{
			width: 803px;
			height: 54px;
			font-size: 50px;
			font-family: Adobe Heiti Std R;
			line-height: 72px;
			margin-bottom: 36px;
		}
		.slogan{
			width: 799px;
			height: 44px;
			font-size: 16px;
			font-family: Adobe Heiti Std R;
			line-height: 28px;
			margin-bottom: 40px;
		}
		.button{
			width: 120px;
			height: 36px;
			color: #1A86FF;
			font-size: 16px;
			line-height: 36px;
			text-align: center;
			border-radius: 20px;
			background-color: #FFFFFF;
			cursor: pointer;
		}
	}
</style>
