<template>
	<div id="banner">
		<el-carousel :interval="5000" arrow="always" :height="bannerHeight + 'px'" :width="bannerWidth + 'px'">
			<el-carousel-item v-for="(item,index) in imgList" :key="index">
				<img :src="item.url" ref="bannerImg" @load="imgLoad" style="width: 1920px; height: 750px;">
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	import {queryImageByStatus} from "@/api/front";
	export default {
		data() {
			return {
				/* 轮播图变量 */
				imgList: [],
				bannerHeight: '', // 图片父容器高度
				bannerWidth: '', //图片父容器宽度
			}
		},
		mounted() {
			this.queryBannerImage()
		},
		methods: {
			imgLoad() {
				this.$nextTick(() => {
					this.bannerHeight = this.$refs.bannerImg[0].height;
					this.bannerWidth = this.$refs.bannerImg[0].width;
				})
			},
			queryBannerImage(){
				queryImageByStatus('N').then(data => {
					this.imgList=data.data.list
					this.imgLoad();
				}).catch(error =>{
					this.$message.error(error)
				})
			},
		},

	}
</script>

<style lang="scss" scoped>
	#banner{
		// border-radius: 0 0 50px 50px;
		// overflow: hidden;
	}
</style>
