<template>
	<div class="businessContent">
		<div class="item" v-for="(item,index) in softwareList" :key="index" @click="goSoftwareDetail(item)">
			<div class="image">
				<img :src="item.system_image" alt="">
			</div>
			<div class="text">
				<span>{{item.description}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props: ['data'],
		data(){
			return{
				softwareList:[],
			}
		},
		watch: {
			data: {
				immediate: true, // 这句重要
				handler(val) {
					// console.log("栏目id", val)
					this.softwareList = val
				}
			}
		},
		methods:{
			goSoftwareDetail(softwareItem){
				let routerUrl=this.$router.resolve({
					path: "/software_detail/",
					query: {
						channelId:1,
						id:softwareItem.id,
					},
				})
				window.open(routerUrl.href, '_blank');
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.businessContent{
		padding: 30px 15%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.item{
			width: 380px;
			height: 420px;
			color: #999999;
			border: 1px solid #BFBFBF;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			cursor: pointer;
			.image{
				width: 380px;
				height: 245px;
				img{
					width: 380px;
					height: 245px;
				}
			}
			.text{
				max-height: 175px;
				padding: 30px 50px;
				span{
					font-size: 16px;
					font-family: Adobe Heiti Std R;
					line-height: 30px;
				}
			}
		}
		.item:hover{
			background-color: #1A86FF;
			color: #FFFFFF;
		}
	}
</style>
