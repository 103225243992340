<template>
	<div class="newsContent">
		<div class="item" v-for="(item,index) in newsList" :key="index" @click="goDetail(item)">
			<div class="image">
				<img v-if="(index%2)==0" src="../../assets/fImage/newsIcon2.png">
				<img v-else src="../../assets/fImage/newsIcon1.png">

			</div>
			<div class="text">
				<span class="title">{{item.title}}</span>
				<span class="description">{{item.description}}</span>
			</div>
			<div class="tail"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		data() {
			return {
				newsList: [],
			}
		},
		watch: {
			data: {
				immediate: true, // 这句重要
				handler(val) {
					this.newsList = val
				}
			}
		},
		methods:{
			goDetail(item){
				let routerUrl=this.$router.resolve({
					path: "/detail",
					query: {
						channelId:2,
						id:item.id,
					},
				})
				window.open(routerUrl.href, '_blank');
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.newsContent {
		padding: 0px 16% 100px 16%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.item {
			position: relative;
			cursor: pointer;
			.image {
				width: 300px;
				text-align: center;

				img {
					position: relative;
					z-index: 5;
					bottom: -60px;
				}
			}

			.text {
				width: 300px;
				height: 250px;
				background-color: #FFFFFF;
				border-radius: 10px;

				.title {
					width: 250px;
					height: 50px;
					margin: 72px 25px 5px 25px;
					text-align: center;
					display: inline-block;
					font-size: 18px;
					font-family: Adobe Heiti Std R;
					color: #000000;

					overflow: hidden;
				}

				.description {
					display: inline-block;
					margin: 0 15px 8px 15px;
					width: 270px;
					height: 110px;
					font-size: 12px;
					font-family: MicrosoftYaHei;
					color: #000000;
					line-height: 18px;
					overflow: hidden;
				}
			}

			.tail {
				position: absolute;
				left: 75%;
				width: 0;
				height: 0;
				border-width: 25px;
				border-top: 25px solid #FFFFFF;
				border-left: 40px solid transparent;
				border-right: 0px solid transparent;
				border-bottom-color: transparent;
			}
		}
	}
</style>
