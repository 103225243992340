<template>
	<div class="aboutContent">
		<div class="left">
			<span class="cmpName">武汉三特索道科技发展有限公司</span>
			<span class="cmpInfo">2021年11月19日，武汉三特索道科技发展
			有限公司成立于湖北武汉东湖新技术开发区光谷软
			件园D1栋1楼108室，是由武汉三特索道集团股份有
			限公司与唐山启晟信息科技有限公司强强联手合资
			组建的高科技公司，旨在建设中国索道行业智慧运
			营管理的先进科技企业；公司通过搭建与完善智慧
			索道安全运营管理云平台，全面提升行业索道智慧
			运营管理水平，探索面向索道全产业链的、基于工
			业互联网平台的全方位的服务，为索道的开发建设
			及运营管理提供高质量、系统化的解决方案，为旅
			游行业提供高品质的智慧运营服务。</span>
			<div class="button" @click="goAbout()">查看更多</div>
		</div>
		<div class="right">
			<img src="../../assets/fImage/company1.png" alt="">
		</div>
	</div>
</template>

<script>
	export default{
		methods:{
			goAbout(){
				this.$router.push({
					path: "/channel_detail/"+'4',
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.aboutContent{
		padding: 30px 15%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.left{
			width: 600px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			.cmpName{
				width: 600px;
				height: 50px;
				font-size: 28px;
				font-family: Adobe Heiti Std R;
				color: #2E2E2E;
				line-height: 33px;
				border-bottom: 2px solid #469DFF;
			}
			.cmpInfo{
				width: 600px;
				padding: 10px 0;
				font-size: 21px;
				font-family: Adobe Heiti Std R;
				color: #838383;
				line-height: 33px;
			}
			.button{
				width: 120px;
				height: 36px;
				color: #FFFFFF;
				font-size: 16px;
				line-height: 36px;
				text-align: center;
				border-radius: 20px;
				background-color: #1A86FF;
				cursor: pointer;
				margin-top: 20px;
			}
		}
		.right{
			width: 600px;
			img{
				width: 600px;
				height: 480px;
				border-radius: 20px;
			}
		}
	}
</style>
