<template>
	<div class="title">
		<div class="name">{{name}}</div>
		<div class="line"></div>
		<div class="tip"><pre>科技赋能    智慧服务</pre></div>
	</div>
</template>

<script>
	export default {
		props: {
			titleName: {
				type: String,
				require: ''
			},
		},
		data() {
			return {
				name: '',
			}
		},
		mounted() {
			this.name = this.titleName
			// console.log("标题")
			// console.log(this.name)
		},
	}
</script>

<style lang="scss" scoped>
	.title{
		padding: 50px 15% 20px 15%;
		// background-color: #F6C6CE;
		span{
			display: inline-block;
		}
		.name{
			height: 40px;
			font-size: 40px;
			font-family: Adobe Heiti Std R;
			color: #1A86FF;
			line-height: 40px;
			text-align: center;
		}
		.line{
			width: 85px;
			height: 1px;
			background: #469DFF;
			opacity: 0.7;
			margin: 20px auto;
			box-shadow: 0 4px 8px 0 #469DFF, 0 6px 20px 0 #469DFF;
		}
		.tip{
			height: 24px;
			font-size: 22px;
			font-family: Adobe Heiti Std R;
			color: #000000;
			line-height: 24px;
			text-align: center;
		}
	}
</style>
